<template>
    <div class="products">
        <div class="page-header">
            <h1>{{ $t('Comment Management') }} > {{ $t('Comments') }}</h1>
        </div>
        <div class="page-content">
            <div class="content-header">
                <h2>{{ $t('Comments') }}</h2>
            </div>

            <div class="inventory-list">
                <ul class="list-group">
                    <li class="list-group-item" v-for="comment in comments">
                        <div class="comment-info">{{ $t('Commenter Name') }}: {{ comment.commenter_name }}</div>
                        <div class="comment-info">{{ $t('Created Date') }}: {{ formatDateTime(comment.created_date, 'YYYY-MM-DD HH:mm:ss') }}</div>
                        <div class="comment-info">{{ $t('Related Product') }} : 
                            <router-link class="action-link" :to="{ name: 'EditProduct', params: { id: comment.related_product } }">
                                {{ comment.productName }}
                            </router-link>
                        </div>
                        {{ comment.comment_content }}
                        <div class="action-links">
                            <router-link class="action-link danger" :to="{ name: 'Comment', params: { id: comment.id } }">
                                {{ $t('Delete') }}
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Product from '@/lib/product';
import Common from '@/lib/common';
import Comment from '@/lib/comment';

export default {
    name: 'Comments',
    mounted() {
        this.loadAllComments();
    },
    methods: {
        async loadAllComments() {
            try {
                const loginInfo = Common.getLoginInfo();
                const comments = await Comment.loadAllComments(this.apiUrl, loginInfo);
                for (let i = 0; i < comments.length; i++) {
                    const comment = comments[i];
                    const productName = await this.loadProudctName(comment.related_product);
                    comments[i].productName = productName;
                }
                this.comments = comments;
            } catch (err) {
                console.log(err);
            }
        },
        formatDateTime(timestamp, format) {
            return Common.formatDateTime(timestamp, format);
        },
        async loadProudctName(productId) {
            try {
                const loginInfo = Common.getLoginInfo();
                const product = await Product.loadProduct(this.apiUrl, productId, loginInfo);
                const productName = await product.product_name_zh;
                return productName;
            } catch (err) {
                console.log(err);
            }
        },
        async deleteComment(commentId) {
            try {
                const loginInfo = Common.getLoginInfo();
                await Comment.deleteComment(this.apiUrl, commentId, loginInfo);
                const newComments = this.comments.filter(function (item) {
                    return item.id !== commentId;
                });
                this.comments = newComments;
            } catch (err) {
                console.log(err);
            }
        },
    },
    data() {
        return {
            comments: [],
        }
    },
    computed: mapState({
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}
.list-group{
    margin-left: 20px;
    margin-right: 20px;
}
.comment-info{
    margin-bottom: 5px;
}
.action-links{
        margin-top: 10px;
    }
</style>