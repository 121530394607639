import queryString from 'query-string';

const Comment = {
    async loadAllComments(baseApiUrl, loginInfo) {
        return new Promise(async (resolve, reject) => {
            const query = queryString.stringify({
                token: loginInfo['token'],
            });
            const apiUrl = `${baseApiUrl}/comment?${query}`;
            const fetchResult = await fetch(apiUrl);
            const status = await fetchResult.status;

            //If cannot connect with API server
            if (status !== 200 && status !== 401) {
                const err = 'Cannot loading comments information. Maybe there are some network problem?';
                reject(err);
                return;
            }

            //If user do not login or no permission
            if (status === 401) {
                const err = 'Sorry, You don\'t had permission to access it.';
                reject(err);
                return;
            }

            const resultJson = await fetchResult.json();

            resolve(resultJson);
        });
    },
    async deleteComment(baseApiUrl, commentId, loginInfo){
        return new Promise(async(resolve, reject) => {
          const deleteValues = {
            userId: loginInfo['userId'],
            token: loginInfo['token'],
          };
    
          const apiUrl = `${baseApiUrl}/comment/${commentId}`;
          const fetchResult = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
             'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(deleteValues),
          });
    
          const status = await fetchResult.status;
    
          //If cannot connect with API server
          if(status !== 200 && status !== 401){
            const err = 'Cannot delete comment information. Maybe there are some network problem?';
            reject(err);
            return;
          }
    
          //If user do not login or no permission
          if(status === 401){
            const err = 'Sorry, You don\'t had permission to access it.';
            reject(err);
            return;
          }
    
          const resultJson = await fetchResult.json();
    
          resolve(resultJson);
        });
      },
}

export default Comment;